import { notification } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import React from 'react'

export default function ErrorNotification(msg) {
  return (
    notification.error({
      message: "Oops! Something went wrong :(",
      description: msg,
      icon: <WarningFilled style={{ color: '#e33627' }}/>
    })
  ) 
}
