import { useApolloClient } from '@apollo/client';
import { Alert, Result, Button } from 'antd'
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/FirebaseAuth/AuthContext';
import React, { useState } from 'react'
import Error from '../Error/GenericError';
import ErrorSVG from '../../images/icons/Error.svg'
import SuccessSVG from '../../images/icons/Success.svg'

const ErrorIcon = () => (
  <img src={ErrorSVG} width="20%" alt=""/>
)

const SuccessIcon = () => (
  <img src={SuccessSVG} width="20%" alt=""/>
)

export default function ResendVerification() {
  const { sendEmailVerification, logout, currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const history = useHistory()
  const client = useApolloClient()

  async function handleResend() {
    setLoading(true)
    setError(null)
    setMessage(null)
    try {
      await sendEmailVerification();
      setMessage("A verification link has been resent to your email.")
    } catch(error) {
      setError(error.message)
    } 
    setLoading(false)
  }

  async function goHome() {
    try {
      await logout()
      client.resetStore()
      history.replace('/');
    } catch {
      return (
        <Error status="500" message="Sorry, something went wrong." />
      )
    }
  }

  if (currentUser.emailVerified) {
    return (
      <Result 
        icon={<SuccessIcon />}
        status="success" 
        title="Your email has already been verified."
        extra={[
          <Button 
            key="dashboard" 
            type="primary" 
            onClick={() => {history.replace('/authenticated/dashboard')}}>
              View Console
          </Button>,
        ]}
      />
    )
  } 

  return (
    <div className="account-verification-warning">
      {error && <Alert type="error" message={error}/>} 
      {message && <Alert type="success" message={message}/>} 
      <Result 
        icon={<ErrorIcon />}
        status="warning" 
        title="Login Failed"
        subTitle="Please verify your email address. If you did not receive the verification email, we can send you a new one."
        extra={[
          <Button key="home" type="primary" onClick={goHome}>Back Home</Button>,
          <Button key="resend" disabled={loading} onClick={handleResend}>Resend Verification Email</Button>
        ]}
      />
    </div>
  )
}
