import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_QUICKRUN_PROJECT } from '../graphql/query';
import { useAuth } from '../context/FirebaseAuth/AuthContext';
import { Row, Spin } from 'antd';
import { ProjectContextProvider } from '../context/ProjectInfo/ProjectContext';
import { CREATE_PROJECT } from '../graphql/mutation';
import PageLayout from './Layout/Layout';
import ProjectInfo from './ProjectInfo';

export default function QuickRun() {
  const { currentUser } = useAuth()
  const { data, loading, refetch } = useQuery(GET_QUICKRUN_PROJECT,
    {
      variables: { email: currentUser.email }
    }
  )
  const [createQuickRunProject, {loading: createProjectLoading}] = useMutation(
    CREATE_PROJECT, {
      onCompleted() {
        refetch()
      }
    }
  );

  useEffect(() => {
    if (data && data.quickRunProject.length === 0) {
      createQuickRunProject({ 
        variables: {
          email: currentUser.email,
          description: "user quick run project",
          title: "" 
        }
      });
    }
  }, [data, createQuickRunProject, currentUser.email])

  if (loading || createProjectLoading) {
    return (
      <PageLayout>
        <Spin style={{position: 'relative', left: '50%', top: '45%'}}/>
      </PageLayout>
    )
  }
  
	return (
    <PageLayout>
      <ProjectContextProvider>
        <Row style={{height: '6vh', width: '100%'}}>
          <h1> Quick Run</h1>
        </Row>
        <Row style={{height: '93%', width: '100%'}}>
          <ProjectInfo project={data.quickRunProject[0]}/> 
          {/* <div style={{overflow: 'auto', width: '100%', height: '40vh'}}>
            <ProjectInfo project={data.quickRunProject[0]}/> 
          </div> */}
        </Row>
      </ProjectContextProvider>
    </PageLayout>
  )
}