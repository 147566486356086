import { Space, PageHeader, Table, Button, Spin, Modal, Form, Row } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PROJECT, DELETE_PROJECT, EDIT_PROJECT } from '../graphql/mutation';
import { LIST_PROJECTS } from '../graphql/query';
import { useAuth } from '../context/FirebaseAuth/AuthContext';
import { ProjectContextProvider } from '../context/ProjectInfo/ProjectContext';
import React, { useEffect, useState } from 'react';
import PageLayout from './Layout/Layout';
import AddProject from './AddProject';
import EditProject from './EditProject';
import ProjectInfo from './ProjectInfo';
import './styles/Spin.css'
import './styles/Dashboard.css'


export default function Dashboard() {
  const [selectedProject, setSelectedProject] = useState(null)
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [projectOpened, setProjectOpened] = useState(false);
  const [editProjectModal, setEditProjectModal] = useState(false);
  const [editProjectData, setEditProjectData] = useState({});
  const [projectList, setProjectList] = useState([]) //list of user projects
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //selected projects in dashboard
  const [selectedProjectID, setSelectedProjectID] = useState([]); //opened project info
  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const { currentUser } = useAuth()
  const { data, loading } = useQuery(LIST_PROJECTS, 
    {
      variables: { email: currentUser.email }
    }
  );
  const [createProject, {loading: createProjectLoading}] = useMutation(
    CREATE_PROJECT,
  );
  const [editProject, {loading: editProjectLoading}] = useMutation(
    EDIT_PROJECT
  );
  const [deleteProject, {loading: deleteProjectLoading}] = useMutation(
    DELETE_PROJECT
  )

  const columns = [
    {
      title: 'Project Title',
      dataIndex: 'title',
      key: 'title',
      width: "20%"
    },
    {
      title: 'Date Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      defaultSortOrder: 'descend',
      width: "15%",
      sorter: (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: "35%"
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="small">
          <Button onClick={() => {
            setProjectOpened(true)
            setSelectedProject(data.userProjects[record.key])}
          }>
            Open
          </Button>
          <Button onClick={() => {
            setSelectedProject(data.userProjects[record.key])
            setEditProjectData(record)
            setEditProjectModal(true)
          }}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      const tmp = data["userProjects"].map((obj, index) => {
        return {
          key: index,
          title: obj["title"],
          description: obj["description"],
          dateCreated: obj["dateCreated"]
        } 
      })
      setProjectList(tmp)
    }
  }, [data])

  const onSelectChange = (selectedRows) => {
    const uuid = selectedRows.map(idx => data.userProjects[idx].projectId)
    setSelectedRowKeys(selectedRows);
    setSelectedProjectID(uuid)
  }
  
  const viewProjectTable = () => {
    setProjectOpened(false)
  }

  const handleCreateProject = (values) => {
    setCreateProjectModal(false)
    createProject({ 
      variables: {
        email: currentUser.email,
        description: values.description,
        title: values.title
      },
      refetchQueries: [{
        query: LIST_PROJECTS,
        variables: {email: currentUser.email}
      }]
    });
    createForm.resetFields()
  }

  const handleDeleteProject = () => {
    deleteProject({
      variables: {
        id: selectedProjectID 
      },
      refetchQueries: [
        { 
          query: LIST_PROJECTS, 
          variables: {email: currentUser.email}
        }
      ],
    });
    setSelectedRowKeys([]);
    setSelectedProjectID([])
  }

  const handleEditProject = (values) => {
    setEditProjectModal(false)
    editProject({ 
      variables: {
        id: selectedProject.projectId,
        title: values.title,
        description: values.description,
      },
      refetchQueries: [{
        query: LIST_PROJECTS,
        variables: {email: currentUser.email}
      }]
    });
    editForm.resetFields()
  }

  if (projectOpened) {
    return (
      <div className="dashboard">
        <PageLayout>
          <ProjectContextProvider>
            {/* <Row style={{height: PROJECT_PAGE_HEADER_HEIGHT, width: PROJECT_WIDTH }}> */}
            <Row style={{height: '7%', width: '100%'}}>
              <PageHeader
                title={selectedProject.title} 
                onBack={viewProjectTable} 
                style={{paddingLeft: 0}}
              />
            </Row>
            <Row style={{height: '93%', width: '100%'}}>
              <ProjectInfo project={selectedProject}/> 
            </Row>
          </ProjectContextProvider>
        </PageLayout>
      </div>
    )
  }

  if (loading) {
    return (
      <PageLayout>
        <Spin size="default" style={{position: 'relative', left: "50%", top: "50%"}} />
      </PageLayout>
    )
  }

  return (
    <>
      <PageLayout>
        <Row style={{height: '12%'}}>
          <PageHeader
            className="overview-header"
            title="Project List"
            subTitle="Organize your query compounds by projects"
          >
            <p>You can also use the Quick Run feature if you don't want to create a project</p>
          </PageHeader>
        </Row>
        <Row style={{height: '88%'}}>
          <div className="project-list">
            <Button onClick={() => setCreateProjectModal(true)}>Create Project</Button>
            <Button danger onClick={handleDeleteProject}>Delete Project</Button>
            <Spin spinning={createProjectLoading || editProjectLoading || deleteProjectLoading } delay={100}>
              <Table 
                columns={columns} 
                dataSource={projectList} 
                rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                  columnWidth: "5%"
                }}
                pagination={false}
                scroll={{
                  y: '65vh'
                }}
              />
            </Spin>
          </div>
        </Row>
      </PageLayout>
      <Modal 
        title="Create Project" 
        okText="Create Project" 
        visible={createProjectModal} 
        onOk={createForm.submit} 
        onCancel={() => setCreateProjectModal(false)}
      >
        <AddProject form={createForm} onFinish={handleCreateProject} />
      </Modal>
      <Modal 
        title="Edit Project" 
        okText="Edit Project" 
        visible={editProjectModal} 
        onOk={editForm.submit} 
        onCancel={() => setEditProjectModal(false)}
      >
        <EditProject form={editForm} onFinish={handleEditProject} data={editProjectData}/>
      </Modal>
    </>
  )
}
