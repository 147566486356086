import { Tabs } from 'antd';
import { useProjectContext } from '../context/ProjectInfo/ProjectContext';
import InputTab from './InputTab';
import PredictionTab from './PredictionTab';
import React from 'react'
import './styles/ProjectInfo.css'

const { TabPane } = Tabs;

export default function ProjectInfo(props) {

  const {switchTabs, currentTab, loading} = useProjectContext();

  return (
    <Tabs activeKey={currentTab} onTabClick={switchTabs} className='project-info-tabs'>
      <TabPane tab="Query Compounds" key="input" className='project-info-tabs'>
        <InputTab project={props.project} />
      </TabPane>
      <TabPane tab="Predictions" key="output" disabled={loading} className='project-info-tabs'>
        <PredictionTab project={props.project} />
      </TabPane>
    </Tabs>
  )
}
