import { ResponsiveCalendar } from '@nivo/calendar'
import { useState } from 'react'

const currentYear = new Date().getFullYear()
const years = [currentYear-2, currentYear-1, currentYear]

export default function ActivityCalendar(props) {
  const [displayedYear, setDisplayedYear] = useState(currentYear)
  return (
    <div className='activity-calendar' style={{height: props.height}}>
      <ResponsiveCalendar
        data={props.data}
        from={`${displayedYear}-01-10`}
        to={`${displayedYear}-12-20`}
        emptyColor="#eeeeee"
        colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
        margin={{ top: 5, right: 10, bottom: 0, left: 20 }}
        height={150}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'row',
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: 'right-to-left'
          }
        ]}
      />
      {years.map(year => (
        <span
          key={year}
          style={{
            display: "inline-block",
            padding: "3px 9px",
            cursor: "pointer",
            fontWeight: year === displayedYear ? "800" : "400"
          }}
          onClick={() => setDisplayedYear(year)}
        >
          {year}
        </span>
      ))}
    </div>
  )
}