import HomeLayout from "../Layout/HomeLayout"
import ServicesLogo from '../../images/background/services.png'
import { List } from "antd"
import '../styles/Services.css'


const services = [
  {
    title: 'In silico Genotoxicity Prediction',
    description: 'All of our (Q)SAR models are available as a consulting service on a per compound basis.'
  },
  {
    title: 'Expert Reviews for Potentially Mutagenic Compounds',
    description: 'Scientific expert assessment is critical for the evaluation of mutagenic potential of various chemical stated in the ICH M7 guideline. Contradicting results warrants additional assessment such that an expert reviews, critiques all relevant information and provide supporting evidence to justify a rational conclusion to increase prediction confidence and resolve conflicting results.'
  },
  {
    title: 'Health-based Exposure Limits',
    description: 'We develop OEL/PDE/ADEs with appropriate documentation for known compounds. Our experts review and define exposure levels that cause adverse effects using science-based evidence to derive a defensible exposure limit'
  },
  {
    title: 'Drug Screening',
    description: 'Large-scale compound screening for genotoxic potential to optimize safety in the development of pharmaceuticals and cosmetics ingredients'
  }
]

export default function Services() {
	return (
    <HomeLayout>
      <img
        src={ServicesLogo}
        style={{float: 'right'}}
        alt="services-logo"
      />
      <div className="services-content">
        <h1>Consulting Services</h1>
        <List
          itemLayout="horizontal"
          dataSource={services}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </div>
    </HomeLayout>
  )
}