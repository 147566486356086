import { useLazyQuery } from '@apollo/client';
import { Spin, Image, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { FETCH_EXAMPLES } from '../graphql/query';
import { CONTENT_HEIGHT } from './Layout/Layout';

const TRAINING_EXAMPLES_TABLE_HEIGHT = CONTENT_HEIGHT * 0.42


const columns = [
  {
    title: 'Structure',
    dataIndex: 'molSVG',
    key: 'molSVG',
    render: (text, record) => {
      return (
        <Image className="mol-img" src={`data:image/svg+xml;utf8,${encodeURIComponent(record.molSVG)}`} />
      );},
  }, 
  {
    title: 'CAS No.',
    dataIndex: 'casNo', 
    key: 'casNo',  
  },
  {
    title: 'Label',
    dataIndex: 'label', 
    key: 'label',
  },
  {
    title: () => (<Tooltip title="Represents the Tanimoto distance between the query compound and training example">Similarity (%)</Tooltip>),
    dataIndex: 'similarity', 
    key: 'similarity',
  },
 ];

export default function TrainingExamples(props) {
  const [examples, setExamples] = useState([]);
  const [getExamples, {loading, data}] = useLazyQuery(
    FETCH_EXAMPLES
  )

  useEffect(() => {
    if (props.activeTab === 'examples' && props.queryCompound.avalonPrediction !== 'INVALID') {
      getExamples({variables: {numExamples: 10, queryCompound: props.queryCompound.smiles}})
    }
  }, [props.activeTab, props.queryCompound, getExamples])
  
  useEffect(() => {
    if (data) {
      setExamples(data["trainingExamples"].map((obj, index) => {
        return {
          key: index,
          molSVG: obj["molSVG"],
          casNo: obj["casNo"],
          label: obj["labels"],
          similarity: obj["similarity"]
        }
      })) 
    }
  }, [props.activeTab, data])

  if (!props.queryCompound || props.queryCompound.avalonPrediction === 'INVALID') {
    return <p>Training examples not available.</p>
  }

	return (
    <Spin spinning={loading} delay={100}>
      <div style={{height: TRAINING_EXAMPLES_TABLE_HEIGHT, overflow: "auto"}}>
        {data && props.queryCompound && <Table dataSource={examples} columns={columns} pagination={false}/>}
      </div>
    </Spin>
	)
}
