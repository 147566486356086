import { Result, Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router'
import ErrorSVG from '../../images/icons/Error.svg'

const ErrorIcon = () => (
  <img src={ErrorSVG} width="20%" alt=""/>
)

export default function Error(props) {
  const history = useHistory();

  const goHome = () => {
    history.replace('/')
  }

  return (
    <Result
      icon={<ErrorIcon />}
      title={props.status}
      subTitle={props.message}
      extra={<Button type="primary" onClick={goHome}>Back Home</Button>}
    />
  )
}
