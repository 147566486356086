import React, { useEffect, useState } from 'react';
import { Spin, Result, Button } from 'antd';
import { auth } from '../../firebase';
import { useHistory } from 'react-router';
import Success from '../Success';
import ErrorSVG from '../../images/icons/Error.svg'
import SuccessSVG from '../../images/icons/Success.svg'
import '../styles/Spin.css'

const ErrorIcon = () => (
  <img src={ErrorSVG} width="20%" alt=""/>
)

const SuccessIcon = () => (
  <img src={SuccessSVG} width="20%" alt=""/>
)

export default function VerifyEmail(props) {
  const [error, setError] = useState("")
  const [validCode, setValidCode] = useState(null)
  const [verifiedCode, setVerifiedCode] = useState(false)
  const history = useHistory()

  useEffect(() => {
    auth
      .applyActionCode(props.actionCode)
      .then(() => {
        setValidCode(true)
        setVerifiedCode(true)
      })
      .catch(error => {
        setValidCode(false)
        setVerifiedCode(true)
        setError(error.message)
      })
  }, [props.actionCode])

  if (!verifiedCode) {
    return <Spin size="large" className="spinLoader" />
  } else if (verifiedCode && validCode) {
    return (
      <Success 
        icon={<SuccessIcon />}
        title="Your account has been verified."
        subtitle="You can now sign in with your new account."
      />
    )
  } else if (verifiedCode && !validCode) {
    return (
      <Result
        status="error"
        icon={<ErrorIcon />}
        title="Email verification failed"
        subTitle={error}
        extra={[
          <Button type="primary" key="home" onClick={() => {history.push('/')}}>
            Back Home
          </Button>
        ]}
      />
    )
  }
}
