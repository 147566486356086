import { gql } from '@apollo/client';

// ----------------------- ML -------------------------- //
export const CALL_QSAR = gql`
  mutation callModel($projectId: String!, $queryCompounds: [QueryCompound!]!, $endpoint: String!) {
    createModelReport(projectId: $projectId, queryCompounds: $queryCompounds, endpoint: $endpoint) {
      predictionSummary {
        id
        name
        smiles
        endpoint
        avalonPrediction
        axiomPrediction
      }
    }
  }
`
export const DELETE_MODEL_REPORT = gql`
  mutation deleteModelReport($pk: [String!]!) {
    deleteModelReport(pk: $pk) {
      ok
    }
  }
`
export const CLEAR_PROJECT = gql`
  mutation clearProject($projectId: String!) {
    clearProject(projectId: $projectId) {
      ok
    }
  }
  `
// ----------------------- USERS -------------------------- //

export const AUTHENTICATE_USER = gql`
  mutation authUser($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      success
      user {
        id
      }
    }
  }
`
// ----------------------- PROJECTS -------------------------- //
export const CREATE_PROJECT = gql`
  mutation createProject($email: String!, $description: String!, $title: String!) {
    createProject(email: $email, description: $description, title: $title) {
      project {
        dateCreated
        userEmail
        description
        title
        projectId
      }
    }
  }
`

export const EDIT_PROJECT = gql`
  mutation editProject($id: String!, $description: String!, $title: String!) {
    editProject(id: $id, title: $title, description: $description) {
      project {
        title
        description
        projectId
      }
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: [String!]!) {
    deleteProject(id: $id) {
      ok
    }
  }
`

export const GENERATE_PREDICTION_REPORT = gql`
  mutation generatePredictionReport($endpoint: String!, $id: [Int]!, $sections: [String]!) {
    generatePredictionReport(endpoint: $endpoint, id: $id, sections: $sections) {
      fname
    }
  }
`
