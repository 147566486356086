import PageLayout from "./Layout/Layout";
import React, { useEffect, useState } from 'react'
import { Space, Empty, Card, Button, Col, Dropdown, Menu, Row, Table, Divider, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { FETCH_USER_PREDICTIONS } from "../graphql/query";
import { useAuth } from "../context/FirebaseAuth/AuthContext";
import PieChart from "./Charts/Pie";
import ActivityCalendar from "./Charts/Calendar";
import "./styles/Overview.css"

const columns = [
  {
    title: 'Alert Name',
    dataIndex: 'alertName',
    key: 'name',
  },
  {
    title: 'Frequency',
    dataIndex: 'value',
    key: 'frequency',
  }
]

const fill_color = {
  "NEGATIVE": "#61cdbb",
  "EQUIVOCAL": "#e8c1a0",
  "POSITIVE": "#f47560",
  "INVALID": "#f1e15b"
}

export default function Overview () {
  const { currentUser } = useAuth()
  const [endpoint, setEndpoint] = useState("Mutagenicity") //default endpoint to fetch
  const [avalonCounts, setAvalonCounts] = useState(null)
  const [axiomCounts, setAxiomCounts] = useState(null)
  const { data, loading, refetch } = useQuery(FETCH_USER_PREDICTIONS, 
    {
      variables: { 
        email: currentUser.email,
        endpoint: endpoint
      }
    }
  );

  useEffect(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (data) {
      setAvalonCounts(
        data.userPredictions.avalonCounts.map(
          obj => {
            const label = obj["id"]
            return {
              "id": label,
              "value": obj["value"],
              "color": fill_color[label]
            }
          }
        )
      )
      setAxiomCounts(
        data.userPredictions.axiomCounts.map(
          obj => {
            const label = obj["id"]
            return {
              "id": label,
              "value": obj["value"],
              "color": fill_color[label]
            }
          }
        )
      )
    }
  }, [data])

  const handleMenuClick = (item) => {
    setEndpoint(item.key)
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Mutagenicity">
        Mutagenicity
      </Menu.Item>
    </Menu>
  );

  if (loading) {
    return (
      <PageLayout>
        <Spin size="default" style={{position: 'relative', left: '50%', top: '45%'}}/>
      </PageLayout>
    )
  }

	return (
    <PageLayout>
      <Row className='overview-page-header' style={{height: '15vh'}}>
        <Space size='large'>
          <h1 className='overview-page-header-title'>Overview</h1>
          <Dropdown overlay={menu}>
            <Button>
              {endpoint} <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
        <Divider />
      </Row>
      <Row className='overview-content' style={{height: '80vh', overflow: 'auto'}}>
        <Col className='overview-left-col' style={{height: '75vh'}}>
          <Card
            hoverable
            className='overview-total-predictions'
            style={{height: '15vh', marginBottom: '0.5%'}}
          >
            <h1>{data && data.userPredictions.numPredictions}</h1>
            <h3>Total Predictions </h3>
          </Card>  
          <Row gutter={[6, 0]} className='overview-prediction-stats'>
            <Col span={12} style={{height: '100%'}}>
               <Card hoverable title="Statistical Model Predictions" style={{height: '100%'}}>
                <div 
                  className="avalon-pie-chart" 
                  style={{
                    height: '42vh',
                    // width: '18vw'
                  }}
                >
                  { data && data.userPredictions.numPredictions > 0
                    ? <PieChart data={avalonCounts} />
                    : <Empty />
                  }
                </div>
                <h2 className="prediction-summary-label">Avalon Positive Rate</h2>
              </Card>
            </Col>
            <Col span={12} style={{height: '100%'}}>
              <Card hoverable title="Rule-based Model Predictions" style={{height: '100%'}}>
                <div 
                  className="avalon-pie-chart" 
                  style={{
                    height: '42vh',
                    // width: '18vw'
                  }}
                >
                  { data && data.userPredictions.numPredictions > 0
                    ? <PieChart data={axiomCounts} />
                    : <Empty />
                  }
                  <h2 className="prediction-summary-label">Axiom Positive Rate</h2>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className='overview-right-col' style={{height: '75vh'}}>
          <Card hoverable title="Past Activity" style={{height: '30vh', marginBottom: '0.5%'}}>
            <ActivityCalendar 
              data={data.userPredictions.pastActivity} 
              height={'15vh'}
            />
          </Card>
          <Card title="Alert Frequency" style={{height: '59.5%'}}>
            <Table 
              bordered
              columns={columns} 
              dataSource={data.userPredictions.alertCounts}
              size="small"
              pagination={false}
              scroll={{y: '25vh'}}
            />
          </Card>
        </Col>
      </Row>
    </PageLayout>
  )
}
