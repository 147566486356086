import React, { useState, useEffect } from "react"
import { Input, Form, Button, Card, Alert, Spin, Divider } from "antd"
import { useAuth } from "../../context/FirebaseAuth/AuthContext"
import { UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import { auth } from "../../firebase";
import Icon from '@ant-design/icons';
import EvergreenLogo from '../../images/icons/ColorLogo.svg';
import '../styles/Spin.css'
import '../styles/ForgotPassword.css'

const LogoImage = () => (
  <img src={EvergreenLogo} alt="" width="60%" />
);

const LogoIcon = props => <Icon component={LogoImage} {...props} />;

export default function ForgotPassword() {
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function onFinish(values) {
    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(values.email)
      setMessage("Check your inbox for further instructions")
    } catch(err) {
      setError(err.message)
    }
    setLoading(false)
  }

  return (
    <div className="forgot-password-background">
      <div className="forgot-password">
        <Form
          name="reset_password_email"
          className="reset-password-email-action"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <LogoIcon style={{margin: "auto"}}/>
          <Divider />
          <h3 style={{paddingBottom: "5%"}}>
            Enter the email address associated with your account and 
            we will send you a link to reset your password
          </h3>
          {error && 
            <Alert 
              message={error}
              type="error"
            />
          }
          {message && <Alert message={message} type="success"/>}
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email' }]}
          >
            <Input 
              prefix={<UserOutlined />}
              className="site-form-item-icon" 
              placeholder="Email" 
              type="email"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              Send Email
            </Button>
          </Form.Item>
        </Form>
        <div>
          Need an account? <Link to="/">Sign up</Link> 
        </div>
      </div>
    </div>
  )
}

export function ResetPassword(props) {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [validCode, setValidCode] = useState(false)
  const [verifiedCode, setVerifiedCode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const history = useHistory()
  
  useEffect(() => {
    auth
      .verifyPasswordResetCode(props.actionCode)
      .then((email) => {
        setEmail(email)
        setValidCode(true)
        setVerifiedCode(true)
      })
      .catch(error => {
        setError(error.message)
        setValidCode(false)
        setVerifiedCode(true)
      })
  })

  async function onFinish(values) {
    if (values.newPassword !== values.confirmNewPassword) {
      setError("The new passwords do not match")
      return;
    }
    try {
      setLoading(true)
      setError(null)
      await auth.confirmPasswordReset(props.actionCode, values.newPassword)
      setSuccess(true)
    } catch(error) {
      setError(error.message)
    }
    setLoading(false)
  }
  
  const goHome = () => {
    setLoading(true)
    history.replace('/')
  }

  if (!verifiedCode) {
    return <Spin size="large" className="spinLoader" />
  } else if (success) {
    return (
      <div className="ResetPassword">
        <h1>Password changed</h1>
        <p>You can now sign in with your new password</p>
        <Button type="primary" onClick={goHome} disabled={loading}>Back Home</Button>
      </div>
    )
  } else if (verifiedCode && validCode) {
    return (
      <Card title="Reset Password" style={{width: '20vw', height: '35vh'}}>
        <Form
          name="reset_password"
          className="reset-password"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <p>Reset password for {email} </p>
          {error && 
            <Alert 
              message={error}
              type="error"
            />
          }
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: 'Please enter a new password' }]}
          >
            <Input.Password placeholder="Enter New Password"/>
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            rules={[{ required: true, message: 'Please confirm the new password' }]}
          >
            <Input.Password placeholder="Confirm New Password"/>
          </Form.Item>
          <Form.Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    )
  } else if (verifiedCode && !validCode) {
    return (
      <div className="ResetPassword">
        <h1>Try resetting your password again</h1>
        <p className="error">{error}</p>
        <Button type="primary" onClick={goHome} disabled={loading}>Back Home</Button>
      </div>
    )
  }
}