import { BrowserRouter as Router , Switch, Route} from 'react-router-dom';
import { AuthProvider } from './context/FirebaseAuth/AuthContext';
import React from 'react';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import Help from './components/Help';
import ForgotPassword from './components/Auth/ForgotPassword';
import Action from './components/Auth/EmailActionHandler';
import ResendVerification from './components/Auth/ResendVerification';
import PageNotExist from './components/Error/PageNotExist';
import Overview from './components/Overview';
import QuickRun from './components/QuickRun';
import GetStarted from './components/Home/GetStarted';
import Features from './components/Home/Features';
import Services from './components/Home/Services';
import FAQ from './components/Home/FAQ';
import 'antd/dist/antd.css';
import './components/styles/App.css' 

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path='/' component={GetStarted} />
            <Route path='/faq' component={FAQ} />
            <Route path='/features' component={Features} />
            <Route path='/services' component={Services} />
            <Route path='/forgot-password' component={ForgotPassword} />
            <PrivateRoute path='/authenticated/dashboard' component={Dashboard} />
            <PrivateRoute path='/authenticated/run' component={QuickRun} />
            <PrivateRoute path='/authenticated/help' component={Help} />
            <PrivateRoute path='/authenticated/home' component={Overview} />
            <PrivateRoute path='/verify' component={ResendVerification} />
            <Route path="/action" component={Action} />
            <Route path="/*" component={PageNotExist} />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
