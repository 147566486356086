import { Editor } from 'ketcher-react'
import { StandaloneStructServiceProvider } from 'ketcher-standalone'
import 'ketcher-react/dist/index.css'
import React from 'react';

const structServiceProvider = new StandaloneStructServiceProvider()

export default function MoleculeEditor(props) {

  return (
    <div style={{height: props.height, width: props.width}}>
      <Editor
        staticResourcesUrl={process.env.PUBLIC_URL}
        structServiceProvider={structServiceProvider}
      />
    </div>
  )
}