import { Form, Input, Button, Alert } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { useAuth } from '../../context/FirebaseAuth/AuthContext';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { VERIFY_ACCOUNT_STATUS } from '../../graphql/query';
import '../styles/Login.css'

function Login() {
  const history = useHistory();
  const { login, currentUser, loading, setLoading } = useAuth();
  const [error, setError] = useState(null)
  // const [loading, setLoading] = useState(false)
  const [verifyAccountStatus, { data }] = useLazyQuery(VERIFY_ACCOUNT_STATUS)

  async function onFinish(values) {
    setLoading(true)
    setError(null);
    try {
      const userCredential = await login(values.email, values.password)
      verifyAccountStatus({variables: {email: userCredential.user.email}})
    } catch(error) {
      setError(error.message)
      setLoading(false)
    }
  }
  
  useEffect(() => {
    if (data && data.accountStatus.active) {
      if (currentUser && currentUser.emailVerified) {
        history.push("/authenticated/dashboard")
      } else {
        history.push("/verify")
      }
      setLoading(false)
    }
  }, [data, currentUser, history, setLoading])

  const onAlertClose = () => {
    setError(null)
  }

  return (
    <Form
      name="normal_login"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      {error && 
        <Alert 
          message={error}
          type="error"
          closable
          onClose={onAlertClose}
          className="login-result"
        />
      }
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your email' }]}
        className="login-form"
      >
        <Input 
          prefix={<UserOutlined className="site-form-item-icon" />} 
          placeholder="Email" 
          type="email"
          size="large"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password.' }]}
        className="login-form"
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          size="large"
        />
      </Form.Item>

      <Form.Item>
        <p>
          <a href="/forgot-password">Forgot Password</a>
        </p>
        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Login
