import React, { useState } from 'react'
import { Cascader, message, Button, Tooltip, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useProjectContext } from '../context/ProjectInfo/ProjectContext';
import { 
  DataSheetGrid,
  textColumn,
  keyColumn
} from 'react-datasheet-grid'
import { useMutation } from '@apollo/client';
import { CALL_QSAR } from '../graphql/mutation';

const columns = [
  { ...keyColumn('name', textColumn), title: 'Name', width: 1, minWidth: 200 },
  { ...keyColumn('smiles', textColumn), title: 'SMILES', width: 2 },
]

const options = [
  {
    value: 'Mutagenicity',
    label: 'Mutagenicity'
  }
]

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function InputTable(props) {
  const [endpoint, setEndpoint] = useState("Mutagenicity")
  const [callQSAR] = useMutation(
    CALL_QSAR, {
      onCompleted() {
        message.success('Predictions tab updated!')
        setLoading(false)
        setOutputUpdated(true)
      } 
    }
  );
  const actionColumn = {
    title: '',
    component: CellComponent 
  }
  
  function CellComponent({rowData}) {
    const handleEdit = async () => {
      props.showEditor(true)
      const editor = window.ketcher
      const mol = window.RDKit.get_mol(rowData.smiles).get_smiles()
      if (mol.length === 0) { //invalid
        message.error('Invalid SMILES')
        return
      }
      // const { Molecule } = require('openchemlib/minimal')
      // try {
      //   Molecule.fromSmiles(rowData.smiles)
      // }
      // catch {
      //   message.error('Invalid SMILES')
      //   return
      // }
      editor.setMolecule(rowData.smiles)
    }
    return (
      <Tooltip title="Modify in molecule editor">
        <Button style={{marginLeft: '5%'}} onClick={handleEdit} icon={<EditOutlined/>} />
      </Tooltip>
    )
  }

  const { 
    loading, 
    setLoading, 
    setOutputUpdated,
    spreadsheetItems,
    setSpreadsheetItems
  } = useProjectContext()

  async function handlePredict() {
    setLoading(true)
    await sleep(500)
    if (endpoint.length === 0) {
      message.warning('Please select an endpoint')
      setLoading(false)
      return
    } else {
      var selectedEndpoint = endpoint
    }
    const inputSmiles = spreadsheetItems.filter(obj => obj.smiles !== "" && obj.smiles) 
    if (inputSmiles && inputSmiles.length === 0) {
      message.warning('No input SMILES detected.')
    } else {
      callQSAR({ 
        variables: {
          queryCompounds: inputSmiles,
          endpoint: selectedEndpoint,
          projectId: props.project.projectId
        }
      })
    }
  }

  return (
    <>
      <div className="input-spreadsheet" style={{width: props.width, minWidth: '300px'}}>
        <DataSheetGrid
          data={spreadsheetItems}
          onChange={setSpreadsheetItems}
          columns={columns}
          lockRows={true}
          height={props.height}
          stickyRightColumn={actionColumn}
          rowHeight={30}
        />
      </div>
      <div style={{marginTop: '1vh', position: 'relative', left: '35%'}}>
        <Space>
          <Cascader
            defaultValue={["Mutagenicity"]}
            options={options}
            onChange={(value) => setEndpoint(value)}
          />
          <Button type='primary' loading={loading} onClick={handlePredict}>Predict</Button>
        </Space>
      </div>
    </>
  )
}