import React, { useContext, useState } from 'react';

const ProjectContext = React.createContext()

export function useProjectContext() {
  return useContext(ProjectContext)
}

export function ProjectContextProvider({children}) {
  const [currentTab, setCurrentTab] = useState("input");
  const [loading, setLoading] = useState(false)
  const [outputUpdated, setOutputUpdated] = useState(true)
  const [spreadsheetItems, setSpreadsheetItems] = useState(Array(50).fill({name: "", smiles: ""}))

  function switchTabs(key) {
    setCurrentTab(key)
  }

  const values = {
    switchTabs,
    currentTab,
    loading,
    setLoading,
    outputUpdated,
    setOutputUpdated,
    spreadsheetItems,
    setSpreadsheetItems
  }

	return (
    <ProjectContext.Provider value={values}>
      {children}
    </ProjectContext.Provider>
  )
}