import { Checkbox, Tag, Spin, Card, Table, Row, Col, Tooltip, Button, Divider, Dropdown, Menu, Alert } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RETRIEVE_MODEL_PREDICTIONS } from '../graphql/query';
import { DELETE_MODEL_REPORT, CLEAR_PROJECT, GENERATE_PREDICTION_REPORT } from '../graphql/mutation';
import { useProjectContext } from '../context/ProjectInfo/ProjectContext';
import { storageRef } from '../firebase';
import MoleculeInfo from './MoleculeInfo';
import CommentsPanel from './CommentsPanel';
import Modal from 'antd/lib/modal/Modal';
import './styles/PredictionTab.css'

const REPORTLIMIT = 5

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    width: "25%",
    sorter: (a, b) => a.name.localeCompare(b.name) 
  },
  {
    title: "Endpoint",
    dataIndex: "endpoint",
    key: "endpoint",
    width: "23%",
    render: endpoint => {
      endpoint = endpoint.toLowerCase()
      endpoint = endpoint.charAt(0).toUpperCase() + endpoint.slice(1)
      return endpoint
    },
    ellipsis: true,
  },
  {
    title: "Rule-based Prediction",
    dataIndex: "axiom",
    key: "axiom",
    width: 100,
    render: predictions => renderTags(predictions)
  },
  {
    title: "Statistical Prediction",
    dataIndex: "avalon",
    key: "avalon",
    width: 100,
    render: predictions => renderTags(predictions)
  },
  {
    title: () => (
      <Tooltip title="Represents the predicted probability by the statistical model that the compound will be positive">
        Score
      </Tooltip>),
    dataIndex: "probability",
    key: "probability",
    width: 70,
    render: (value, record) => {
      switch (record.avalon) {
        case "KNOWN_POSITIVE":
          return "1.0" 
        case "KNOWN_NEGATIVE":
          return "0.0"
        default:
          return value 
      }
    }
  }
];

function renderTags(text) {
  let color = 'yellow'
  let tag = null 
  if (text === 'POSITIVE' || text === 'KNOWN_POSITIVE') {
    color = 'red'
    tag = 'POSITIVE'
  }
  if (text === 'NEGATIVE' || text === 'KNOWN_NEGATIVE') {
    color = 'green'
    tag = 'NEGATIVE'
  }
  if (text === 'EQUIVOCAL' || text === 'INVALID') {
    color = 'blue'
    tag = text 
  }
  if (text === 'OUT_OF_DOMAIN') {
    color = 'blue'
    tag = 'OOD'
  }
  return (
    <Tag color={color}>{tag}</Tag>
  )
}

export default function PredictionTab (props) {
  const [displayMol, setDisplayMol] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [alertID, setAlertID] = useState(null);
  const [tableData, setTableData] = useState(null)
  const [downloadOptionsModal, setDownloadOptionsModal] = useState(false)
  const [reportEndpoint, setReportEndpoint] = useState("Mutagenicity")
  const [projectEndpoints, setProjectEndpoints] = useState([])
  const [reportSections, setReportSections] = useState([])
  const [clickedRow, setClickedRow] = useState(undefined)
  const { outputUpdated, setOutputUpdated, currentTab } = useProjectContext();
  const { loading, data, refetch } = useQuery(RETRIEVE_MODEL_PREDICTIONS,
    {
      variables: { projectId: props.project.projectId },
    }
  );
  const [deleteData, {loading: deleteRowLoading}] = useMutation(
    DELETE_MODEL_REPORT
  );
  const [getPredictionReport, {loading: getReportLoading}] = useMutation(
    GENERATE_PREDICTION_REPORT,
    {
      onCompleted(data) {
        // setReportURL(data.generateUrl.url[0])
        const fname = data.generatePredictionReport.fname
        storageRef.child(fname).getDownloadURL()
          .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.onload = (_) => {
              // create a hidden download link, click programmatically, then revoke the link
              const blob = new Blob([xhr.response], {type: 'application/zip'});
              let a = document.createElement("a");
              a.style = "display: none";
              document.body.appendChild(a);
              let url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = `${Date.now()}.zip`; // timestamp file name
              a.click();
              window.URL.revokeObjectURL(url);
            };
            xhr.send();
          })
      },
      onError(err) {
        console.log(err)
      }
    }
  );
  const [clearProject, {loading: clearProjectLoading}] = useMutation(CLEAR_PROJECT,
    {
      variables: {projectId: props.project.projectId}
    }
  );
  
  useEffect(() => {
    if (currentTab === 'output') {
      if (outputUpdated) {
        refetch()
        setOutputUpdated(false)
      }
      if (data && data.modelReports.length > 0) {
        setDisplayMol(data.modelReports[0])
        setAlertID(data.modelReports[0]["alertID"]) 
      }
      if (data && data.modelReports.length === 0) {
        setDisplayMol("")
        setAlertID(null)
      }
    }
  }, [currentTab, refetch, data, outputUpdated, setOutputUpdated])

  useEffect(() => {
    if (data && data.modelReports) {
      let endpoints = []
      let tmp = data.modelReports.map(item => {
        const endpoint = item["endpoint"].charAt(0) + item["endpoint"].slice(1).toLowerCase()
        endpoints.push(endpoint)
        return {
          key: item["id"], //generate unique keys for antd table
          name: item["name"],
          avalon: item["avalonPrediction"],
          axiom: item["axiomPrediction"],
          probability: item["probability"],
          endpoint: item["endpoint"]
        }
      })
      setTableData(tmp)
      setProjectEndpoints([...new Set(endpoints)])
    }
  }, [data])

  if (loading) {
    return <Spin size='small' style={{position: 'absolute', left:'50%', top: '50%'}} /> 
  } 

  const onSelectChange = (selectedRows) => {
    setSelectedRowKeys(selectedRows);
  }

  const handleDeleteRow = async () => {
    await deleteData({
      variables: {
        pk: selectedRowKeys 
      }
    });
    setOutputUpdated(true)
    setSelectedRowKeys([]);
  }

  const handleClearTable = async () => {
    await clearProject()
    setOutputUpdated(true)
    setSelectedRowKeys([]);
    setDisplayMol("")
    setAlertID(null)
  }

  const showDownloadOptions = () => {
    setDownloadOptionsModal(true)
  }

  const generateReport = async () => {
    await getPredictionReport({
      variables: {
        id: selectedRowKeys.map(key => Number(key)),
        endpoint: reportEndpoint,
        sections: reportSections 
      }
    })
    setDownloadOptionsModal(false)
  } 

  const handleCancel = () => {
    setDownloadOptionsModal(false)
  } 

  const handleRowClick = (record, _) => {
    return {
      onClick: () => { 
        let recordId = record.key
        let clickedRowData = data.modelReports.filter(item => recordId === item.id)[0]
        let molAlert = clickedRowData.alertID
        if (molAlert.length > 0) {
          setAlertID(molAlert) 
        } else {
          setAlertID(null)
        }

        if (clickedRow === undefined || clickedRow.key === record.key) {
          setClickedRow(record)
        }
        else {
          setClickedRow(record)
        }
        setDisplayMol(clickedRowData)
      }
    }
  }

  return (
    <Row className='prediction-tab' style={{height: '80vh', overflow: 'auto'}}> 
      <Col className='prediction-left-col' style={{marginRight: '0.5%'}}>
        <Card size='small' title='Submitted Compound' style={{height: '77vh'}}>
          <div style={{width: '100%', height: '35%'}}>
            <MoleculeInfo displayMol={displayMol} />
          </div>
          <Divider />
          <div style={{width: '100%', height: '65%'}}>
            <CommentsPanel alertID={alertID} queryCompound={displayMol} />
          </div>
        </Card>

      </Col>
      <Col className='prediction-right-col'>
        <Card size='small' title='Model Predictions' style={{height: '77vh'}}>
          <Spin spinning={deleteRowLoading || clearProjectLoading} delay={100}>
            <span className="output-table-toolbar">
              <div className="output-table-toolbar-buttons" style={{float: "left"}}>
                <Tooltip title="Download report for selected compounds">
                  <Button onClick={showDownloadOptions} icon={<DownloadOutlined />} />
                </Tooltip>
                <Tooltip title="Delete selected rows">
                  <Button onClick={handleDeleteRow} danger icon={<DeleteOutlined />} style={{marginLeft: 0}}/>
                </Tooltip>
                <Tooltip>
                  <Button onClick={handleClearTable} danger style={{marginLeft: 0, marginBottom: 1}}> Clear All</Button>
                </Tooltip>
              </div>
            </span>
            <Table 
              columns={columns}
              size="small"
              dataSource={tableData}
              style={{cursor: 'pointer'}}
              pagination={false}
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }}
              scroll={{y: '58vh'}}
              onRow={handleRowClick}
              rowClassName={(record) => {
                if (clickedRow) {
                  return clickedRow.key === record.key ? "selected-row" : "" 
                } else {
                  return ""
                }
              }}
            />
          </Spin>
        </Card>
      </Col>
      <Modal 
        title="Generate Report" 
        okText="Download Report"
        visible={downloadOptionsModal} 
        onOk={generateReport} 
        onCancel={handleCancel}
        okButtonProps={{loading: getReportLoading, disabled: selectedRowKeys.length > REPORTLIMIT}}
      >
        {selectedRowKeys.length > REPORTLIMIT && 
          <div>
            <Alert type="warning" message="Due to the amount of time it takes to generate 
            each report, we can only generate less than 5 reports per batch. Sorry!" />
            <Divider />
          </div>
        }
        {getReportLoading && 
          <div>
            <Alert type="info" message="This may take a while. Feel free to close this window and work on something else." />
            <Divider />
          </div>
        }
        <p><b>Included sections (check all for ICH submission)</b></p>
        <Checkbox.Group onChange={checkedValues => setReportSections(checkedValues)}>
          <Row>
            <Checkbox value="avalonSection">Statistical-based model (Avalon) predictions</Checkbox>
          </Row>
          <Row>
            <Checkbox value="axiomSection">Rule-based model (Axiom) predictions</Checkbox>
          </Row>
          <Row>
            <Checkbox value="trainingExamples">Similar examples</Checkbox>
          </Row>
        </Checkbox.Group>
        <Divider />
        <p><b>Select Endpoint:</b></p>
        <Dropdown overlay={(
          <Menu onClick={(item) => setReportEndpoint(item.key)}>
            {projectEndpoints.map(endpoint => {
              return (
                <Menu.Item key={endpoint}>
                  {endpoint}
                </Menu.Item>
              )
            })}
          </Menu>
        )}>
          <Button>
            {reportEndpoint} <DownOutlined />
          </Button>
        </Dropdown>
      </Modal>
    </Row>
  )
} 
