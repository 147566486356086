import React from 'react';
import Error from '../Error/GenericError';
import { ResetPassword } from './ForgotPassword';
import VerifyEmail from './VerifyEmail';
import * as QueryString from "query-string"

// import VerifyEmail from './VerifyEmail';

// example link: http://localhost:3000/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy

// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience

function Action(props) {
  const urlSearchParams = QueryString.parse(props.location.search)
  const actionCode = urlSearchParams.oobCode
  const mode = urlSearchParams.mode

  // (Optional) Get the API key from the query parameter.
  // const apiKey = props.location.query.apiKey;

  // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      // Display email recovery handler and UI.
      return <ResetPassword actionCode={actionCode} />;

    case 'verifyEmail':
      // Display email verification handler and UI.
      return <VerifyEmail actionCode={actionCode} />;

    default:
      // Error: invalid mode.
      return (
        <Error status="404" message="Sorry, the page you visted does not exist." />
      );
  }
}

export default Action;