import { Alert, Input, Form, Button, Divider, Descriptions, Spin } from 'antd';
import { useAuth } from '../context/FirebaseAuth/AuthContext';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_INFO } from '../graphql/query';
import React, { useState } from 'react'
import firebase from 'firebase/app'
import './styles/Spin.css'

export default function Settings() {
  const { currentUser } = useAuth();
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const { data } = useQuery(GET_ACCOUNT_INFO, 
    {
      variables: { email: currentUser.email }
    }
  );

  async function onFinish(values) {
    setLoading(true)
    try {
      await confirmCurrentPassword(values.currentPass)
      setError(null)
    } catch {
      setError("Account password is incorrect")
      setLoading(false)
      return;
    }

    if (values.newPass !== values.confirmNewPass) {
      setError("The new passwords do not match.")
    } else {
      setError(null)
      try {
        await currentUser.updatePassword(values.newPass)
        setMessage("Password updated")
      } catch(err) {
        setError(err.message)
      }
    }
    setLoading(false)
  }

  async function confirmCurrentPassword(password) {
    const credential = firebase.auth.EmailAuthProvider.credential(
      currentUser.email,
      password
    );
    await currentUser.reauthenticateWithCredential(credential)
  }

  if (!data) {
    return (
      <Spin size='default' style={{position: 'relative', left: "45%"}}/>
    )
  }

  return (
    <div className='app-settings'>
      <Descriptions title="Account Status" size="small" bordered>
        <Descriptions.Item 
          label="License Type" 
          labelStyle={{width: "30%"}} 
          span={3}
        >
          {data.accountInfo.license}
        </Descriptions.Item>
        <Descriptions.Item label="Expiry Date" span={3}>
          {data.accountInfo.expireDate}
        </Descriptions.Item>
        <Descriptions.Item 
          label="Account Status" 
          span={3}
          contentStyle={{
            fontWeight: 'bold',
            color: data.accountInfo.active ? 'green' : 'red',
          }}
        > 
          {data.accountInfo.active ? "Active" : "Expired"}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <h3><b>Change Password </b></h3>
      <p></p>
      {error && <Alert type="error" message={error} style={{marginBottom: "3%"}} />}
      {message && <Alert type="success" message={message} style={{marginBottom: "3%"}} />}
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Current Password"
          name="currentPass"
          rules={[{ required: true, message: 'Please enter current password.' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPass"
          rules={[{ required: true, message: 'Enter new password.' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmNewPass"
          rules={[{ required: true, message: 'Confirm new password.' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            Update Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
