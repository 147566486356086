import HomeLayout from "../Layout/HomeLayout";
import { List } from "antd";
import '../styles/FAQ.css'

const faq = [
  {
    title: 'What are the use cases for your app?',
    description: 'Our algorithms enable users to conduct QSAR genotoxicity analysis (Ames test) for ICH M7 compliant regulatory submissions to regulatory authorities (i.e. FDA, Health Canada, ECHA, etc.). We are also planning to add more toxicological endpoints for other use cases, so stay tuned.'
  },
  {
    title: 'How do I gain access to your (Q)SAR models?',
    description: 'Our mission is to remove all barriers of access when conducting toxicology evaluations and to facilitate collaborations within a company. As such, our app is sold as a one-year organizational license, where one valid license enables unlimited company-wide usage and provides access to all of our toxicological endpoints. Please contact us at team@neuraltox.com for more information.'
  },
  {
    title: 'Which toxicological endpoints are available?',
    description: 'Currently, only the bacterial mutagenicity endpoint is available.'
  },
  {
    title: 'Are your models ICH M7 compliant?',
    description: 'Yes. We adhere to the ICH M7 guidelines by providing both statistical-based and rule-based models.'
  },
  {
    title: 'How much does an annual license cost?',
    description: 'We charge a flat rate for our annual license based on the size of your company. Please contact us at team@neuraltox.com for details.'
  },
]

export default function FAQ() {
	return (
    <HomeLayout>
      <div className="faq-content">
        <h1>FAQ</h1>
        <List
          itemLayout="horizontal"
          dataSource={faq}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </div>
    </HomeLayout>
  )
}