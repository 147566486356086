import React from 'react'
import { Form, Input } from 'antd'

const { TextArea } = Input; 

export default function AddProject(props) {
  return (
    <Form 
      form={props.form} 
      onFinish={props.onFinish}
      initialValues={{
        title: "",
        endpoint: ["Mutagenicity"],
        description: ""
      }}
    >
      <p><b>Project Title</b></p>
      <Form.Item 
        name="title" 
        rules={[{ required: true, message: 'Please enter a project title' }]}
      >
        <Input placeholder="Enter project title" />
      </Form.Item>
      <p><b>Description (optional)</b></p>
      <Form.Item name="description">
        <TextArea showCount rows={5} maxLength={150} />
      </Form.Item>
    </Form>
  )
}
