import { Row, Col, List } from "antd"
// import RadarPlot from "../Charts/Radar"
import HomeLayout from "../Layout/HomeLayout"
import '../styles/Features.css'

const features = [
  {
    title: 'Regulatory Compliant',
    description: 'We offer both statistical-based and rule-based models for ICH M7 compliance.'
  },
  {
    title: 'Expert Comments',
    description: 'Alerts that are flagged by the rule-based model are described in detailed comments with supporting references.'
  },
  {
    title: '100% Chemical Coverage',
    description: 'Receive less out of domain predictions.'
  },
  {
    title: 'Strong model performance',
    description: 'Our models were developed with state-of-the-art machine learning algorithms.'
  },
  {
    title: 'Accessible Anywhere',
    description: 'Securely access our evaluation tools from the web.'
  },
]

export const AxiomBenchmark = [
  {
    "metric": "Sensitvity (strong mutagens)",
    "Neuraltox AI (rule-based)": 77,
    "Other commercial vendors (average)": 75
  },
  {
    "metric": "Sensitvity",
    "Neuraltox AI (rule-based)": 63,
    "Other commercial vendors (average)": 60
  },
  {
    "metric": "Specificity",
    "Neuraltox AI (rule-based)": 78,
    "Other commercial vendors (average)": 83
  },
  {
    "metric": "Accuracy",
    "Neuraltox AI (rule-based)": 76,
    "Other commercial vendors (average)": 79
  },
  {
    "metric": "Coverage",
    "Neuraltox AI (rule-based)": 100,
    "Other commercial vendors (average)": 93
  }
]

export const AvalonBenchmark = [
  {
    "metric": "Sensitvity (strong mutagen)",
    "Neuraltox AI (statistical-based)": 76.3,
    "Other commercial vendors (average)": 70
  },
  {
    "metric": "Sensitvity",
    "Neuraltox AI (statistical-based)": 58,
    "Other commercial vendors (average)": 55
  },
  {
    "metric": "Specificity",
    "Neuraltox AI (statistical-based)": 85.8,
    "Other commercial vendors (average)": 84.3
  },
  {
    "metric": "Accuracy",
    "Neuraltox AI (statistical-based)": 82,
    "Other commercial vendors (average)": 80
  },
  {
    "metric": "Coverage",
    "Neuraltox AI (statistical-based)": 100,
    "Other commercial vendors (average)": 92
  }
]

export default function Features() {
	return (
    <HomeLayout>
      <div className='features-content'>
        <div className='features-description'>
          <Row gutter={[0, 0]}>
            <Col span={8}>
              <h1>Features</h1>
            </Col>
            <Col span={14}>
              <List
                itemLayout="horizontal"
                dataSource={features}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </div>
        {/* <h1 style={{marginTop: '2vh'}}>Model Performance</h1>
        <div className='benchmark-plot'>
          <Row gutter={[0, 5]}>
            <Col span={12} style={{height: window.innerHeight * 0.35}}>
              <RadarPlot 
                data={AvalonBenchmark} 
                keys={['Neuraltox AI (statistical-based)', 'Other commercial vendors (average)']}
                indexBy="metric"
              />
            </Col>
            <Col span={12} style={{height: window.innerHeight * 0.35}}>
              <RadarPlot 
                data={AxiomBenchmark} 
                keys={['Neuraltox AI (rule-based)', 'Other commercial vendors (average)']}
                indexBy="metric"
              />
            </Col>
          </Row>
        </div>
        <p style={{width: window.innerWidth * 0.45}}>
          <b>Note: </b>Both models were benchmarked against the  
          <a href="http://www.nihs.go.jp/dgm/2nd_amesqsar.html" target="_blank" rel="noreferrer"> NIHS challenge compounds </a>
          to determine the performance for the bacterial mutagenicity endpoint. These metrics are <i>not</i> adjusted 
          for chemical coverage, which means that vendors with less than 100% coverage are not penalized 
          for out-of-domain predictions.
        </p> */}
      </div>
    </HomeLayout>
  )
}