import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import React from 'react'

export default function Success(props) {
  const history = useHistory();

  const goHome = () => {
    history.push("/")
  }

  return (
    <Result
      status="success"
      icon={props.icon}
      title={props.title}
      subTitle={props.subtitle}
      extra={[
        <Button type="primary" onClick={goHome}>
          Back Home
        </Button>
      ]}
    />
  )
}