import React, { useEffect } from 'react'
import { Form, Input } from 'antd'

const { TextArea } = Input; 

export default function EditProject(props) {

  useEffect(() => {
    if (props.data) {
      props.form.setFieldsValue(props.data)
    }
  })
  
  return (
    <Form 
      form={props.form} 
      onFinish={props.onFinish}
    >
      <p><b>Project Title</b></p>
      <Form.Item 
        name="title" 
        rules={[{ required: true, message: 'Please enter a project title' }]}
      >
        <Input />
      </Form.Item>
      <p><b>Description (optional)</b></p>
      <Form.Item name="description">
        <TextArea showCount rows={5} maxLength={150} />
      </Form.Item>
    </Form>
  )
}
