import { Tabs, Divider, Row, Col } from "antd"
import { ReactComponent as WelcomeLogo } from '../../images/icons/Welcome.svg'
import Login from "../Auth/Login";
import Signup from "../Auth/Signup";
import HomeLayout from "../Layout/HomeLayout";
import '../styles/GetStarted.css'

const { TabPane } = Tabs;

export default function GetStarted() {
	return (
    <HomeLayout>
      <div className="welcome-logo" style={{maxWidth: window.innerWidth * 0.3}}>
        <WelcomeLogo />
      </div>
      <div className="about-text">
        <Row justify='center' gutter={[0, 0]}>
          <Col span={5}>
            <h1>About</h1>
          </Col>
          <Col span={14}>
            <p>
              Neuraltox AI offers statistical-based and rule-based models for toxicology evaluation.
              This complementary method supports both ICH M7 workflows as well as early-stage drug screening.
              {/* For more information, please contact us at  
              <a href="mailto:team@neuraltox.com"> team@neuraltox.com</a>. */}
            </p>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="auth-component">
        <Tabs defaultActiveKey="login" type="card" size='small' tabBarStyle={{width: "60%"}}>
          <TabPane tab="Login" key="login">
            <Login />
          </TabPane>
          <TabPane tab="Register" key="signup">
            <Signup />
          </TabPane>
        </Tabs>
      </div>
    </HomeLayout>
  )
}