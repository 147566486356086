import { Divider, Image, Row, Col, Typography } from 'antd'
import React from 'react'
import PageLayout from './Layout/Layout'
import demo from '../images/demo.gif'

const { Title, Text, Paragraph } = Typography;

function Help() {
  return (
    <PageLayout>
      <Row style={{height: '10%', width: '100%'}}>
        <h1>Help and FAQ</h1>
      </Row>
      <div style={{overflow: 'auto', height: '90%'}}>
        <Row style={{height: '100%', width: '100%'}} gutter={[20, 0]}>
          <Col span={12}>
            <Title level={5}>How do I run a prediction?</Title>
            <Paragraph>
              You can run a prediction using <Text code>Quick Run</Text> from the menu bar
              or organize your query compounds through <Text code>Projects</Text>. Then, you
              can either copy and paste your SMILES directly to the spreadsheet or add a drawn 
              structure from the molecule editor. Once the query compounds are prepared, click
              the <Text code>Predict</Text> button to run inference.
            </Paragraph>
            <Image src={demo} />
            <Divider />

            <Title level={5}>What does the predicted score represent?</Title>
            <Paragraph>
              This value ranges from 0 to 1 and represents the probability of a positive outcome by the QSAR model.
              For example, a compound with a predicted score of 0.05 can be interpreted as 5% chance that
              it is positive, but will be classified as negative since the score is below the negative 
              classification threshold of 0.4. 
            </Paragraph>
            <Divider />

            <Title level={5}>How do I view the model predictions?</Title>
            <Paragraph>
              All model predictions can be viewed under the <Text code>Predictions</Text> tab.
            </Paragraph>

          </Col>
          <Col span={12}>
            <Title level={5}>What do the prediction labels mean?</Title>
            <Paragraph>
              Our statistical based model (Avalon) can return 5 possible predictions:
            </Paragraph>
            <Paragraph>
              <ul>
                <li style={{paddingBottom: '10px'}}>
                  <Text strong>Positive:</Text> The query compound is predicted to be positive for the 
                  selected endpoint. For the mutagenicity endpoint, a compound will be classified as
                  Positive when the predicted score is greater than 0.6.
                </li>
                <li style={{paddingBottom: '10px'}}>
                  <Text strong>Negative:</Text> The query compound is predicted to be negative for the 
                  selected endpoint. For the mutagenicity endpoint, a compound will be classified as
                  Negative when the predicted score is less than 0.4.
                </li>
                <li style={{paddingBottom: '10px'}}>
                  <Text strong>Invalid:</Text> The input SMILES is invalid. 
                </li>
                <li style={{paddingBottom: '10px'}}>
                  <Text strong>Equivocal:</Text> The model is uncertain about its prediction. For the mutagenicity endpoint,
                  a statistical-based model will return <Text code> Equivocal</Text> when the predicted probability
                  is between 0.4 - 0.6 in accordance with the FDA regulations for ICH M7 QSAR evaluation. 
                </li>
                <li style={{paddingBottom: '10px'}}>
                  <Text strong>Out of domain (OOD):</Text> The model cannot make a reliable prediction for the
                  query compound. Our statistical model will only return <Text code>Out of Domain</Text> for inorganic
                  compounds.
                </li>
              </ul>
            </Paragraph>
            <Paragraph>
              Our rule-based model (Axiom) will only return <Text code>Positive</Text>, <Text code>Negative</Text>, and
              <Text code>Invalid</Text> predictions. The definitions for these labels are the same as above.
            </Paragraph>
          </Col>
        </Row>
      </div>
    </PageLayout>
  )
}

export default Help
