import React, { useContext, useState, useEffect } from "react"
import { auth } from "../../firebase"
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useHistory } from "react-router-dom";
import { onError } from "@apollo/client/link/error";
import ErrorNotification from "../../components/Error/ErrorNotification";
import { message } from "antd";

/*
TODO:
1. add organization id to jwt; custom claims/security rules
2. cron jobs to reduce cold start times
*/

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const history = useHistory()
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [authToken, setAuthToken] = useState("")
  
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }
  
  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }
  
  function logout() {
    return auth.signOut()
  }
  
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function sendEmailVerification() {
    return auth.currentUser.sendEmailVerification()
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    if (loading) {
      message.loading('Loading application...', 0)
    } else {
      message.destroy()
    }
  }, [loading])

  /*
     This method causes leads to funny race conditions. Auth header
     is not set before the first graphql query is made.
  */
  // useEffect(() => {
  //   if (currentUser) {
  //     currentUser.getIdToken().then((token => setAccessToken(token))); 
  //   }
  // }, [currentUser])

  function createApolloClient() {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_BACKEND
    })

    const authLink = setContext(async (_, { headers }) => {
      const token = await currentUser.getIdToken() 
      setAuthToken(token)
      const email = currentUser.email
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : "",
          email: email? `${email}` : "" 
        }
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError}) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
        setLoading(false)
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        setLoading(false)
        
        switch (networkError.statusCode) {
          case 401:
            ErrorNotification('Your session has expired. Please try logging in again')
            auth.signOut()
            history.replace('/')
            break;
          case 403:
            ErrorNotification('Your account has expired or does not belong to a licensed organization. Please contact us for more information')
            auth.signOut()
            history.replace('/')
            break;
          default:
            break;
        }
      } 
    });

    const client = new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache()
    })
    return client 
  }

  const values = {
    loading,
    setLoading,
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    sendEmailVerification,
    createApolloClient,
    authToken
  }

  return (
    <AuthContext.Provider value={values}>
      <ApolloProvider client={values.createApolloClient()}>
        {children}
      </ApolloProvider>
    </AuthContext.Provider>
  )
}
