import { ResponsivePie } from '@nivo/pie'

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0
    let positive = 0
    dataWithArc.forEach(datum => {
      if (datum.id === 'POSITIVE') {
        positive = datum.value
      }
      total += datum.value
    })
    const positiveRate = Math.round((positive / total) * 100)

    return (
      <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
              fontSize: '4vh',
              fontWeight: 500,
          }}
      >
        {positiveRate}% 
      </text>
    )
}

export default function PieChart(props) {
  return (
    <ResponsivePie
      data={props.data}
      colors={{ datum: 'data.color' }}
      margin={{ top: 50, right: 15, left: 15 }}
      innerRadius={0.8}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateX: -10,
          translateY: -50,
          itemWidth: 100,
          itemHeight: 20,
          itemsSpacing: 0,
          symbolSize: 15,
          itemDirection: 'left-to-right'
        }
      ]}
    />
  )
}