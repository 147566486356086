import { Row, Col, Layout, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../context/FirebaseAuth/AuthContext';
import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import React from 'react'
import Icon from '@ant-design/icons';
import Error from '../Error/GenericError';
import Modal from 'antd/lib/modal/Modal';
import Settings from '../Settings';

import LogoSVG from '../../images/icons/MenuLogo.svg'
import DashboardSVG from '../../images/icons/Dashboard.svg';
import HomeSVG from '../../images/icons/Home.svg'
import SettingSVG from '../../images/icons/Settings.svg'
import HelpSVG from '../../images/icons/Help.svg'
import QuickRunSVG from '../../images/icons/QuickRun.svg'
import LogoutSVG from '../../images/icons/Logout.svg'

import '../styles/Layout.css'

const { Sider } = Layout;

const MenuLogoSVG = () => (
  <img src={LogoSVG} alt="" width="60%"/>
);

const HomeIcon = () => (
  <img src={HomeSVG} alt="" width="18px"/>
);

const QuickRunIcon = () => (
  <img src={QuickRunSVG} alt="" width="18px"/>
);

const DashboardIcon = () => (
  <img src={DashboardSVG} alt="" width="18px"/>
);

const SettingIcon = () => (
  <img src={SettingSVG} alt="" width="18px"/>
);

const HelpIcon = () => (
  <img src={HelpSVG} alt="" width="18px"/>
);

const LogoutIcon = () => (
  <img src={LogoutSVG} alt="" width="18px"/>
);

const MenuLogoIcon = props => <Icon component={MenuLogoSVG} {...props} />;

export const MENU_WIDTH = window.innerWidth * 0.12
export const MENU_HEIGHT = window.innerHeight
export const FILLER_COLUMN_WIDTH = window.innerWidth * 0.04
export const FILLER_ROW_HEIGHT = window.innerHeight * 0.05
export const CONTENT_HEIGHT = window.innerHeight - 2 * FILLER_ROW_HEIGHT
export const CONTENT_WIDTH = window.innerWidth - MENU_WIDTH - 2 * FILLER_COLUMN_WIDTH

export default function PageLayout (props) {
  const { logout } = useAuth();
  const [showSettings, setShowSettings] = useState(false);
  const client = useApolloClient()
  const history = useHistory();

  const changeSettings = () => {
    setShowSettings(true);
  };

  const handleOk = () => {
    if (showSettings) {
      setShowSettings(false);
    }
  };

  const handleCancel = () => {
    if (showSettings) {
      setShowSettings(false);
    }
  };

  async function handleLogout() {
    try {
      await logout()
      client.resetStore()
      history.replace('/');
    } catch {
      return (
        <Error status="500" message="Sorry, something went wrong." />
      )
    }
  }

  return (
    <Row wrap={false}>
      <Col style={{minWidth: '220px', width: '220px', height: '100vh'}}>
        <Sider
          className="sider"
          width={'100%'}
          style={{height: '100%'}} 
        >
          <MenuLogoIcon className="menu-logo" />
          <div className="app-menu">
            <Menu theme="dark" mode="inline">
              <Menu.Item key="home" icon={<HomeIcon />} className="custom-item">
                <Link to="/authenticated/home" className="nav-title">Overview</Link>
              </Menu.Item>
              <Menu.Item key="setting" icon={<SettingIcon />}>
                <span className="nav-title" onClick={changeSettings}>Settings</span>
              </Menu.Item>
              <Menu.Item key="run" icon={<QuickRunIcon />}>
                <Link to="/authenticated/run" className="nav-title">Quick Run</Link>
              </Menu.Item>
              <Menu.Item key="dashboard" icon={<DashboardIcon />}>
                <Link to="/authenticated/dashboard" className="nav-title">Projects</Link>
              </Menu.Item>
              <Menu.Item key="help" icon={<HelpIcon />}>
                <Link to="/authenticated/help" className="nav-title">Help</Link>
              </Menu.Item>
              <Menu.Item key="log-out" icon={<LogoutIcon />}>
                <span className="nav-title" onClick={handleLogout}>Sign Out </span>
              </Menu.Item>
            </Menu>
          </div>
        </Sider>
      </Col>
      <Col style={{width: '3vw', minWidth: '30px'}}></Col>
      <Col style={{width: '85vw'}}>
        <Row style={{height: '2vh', minHeight: '30px'}}></Row>
        <Row style={{width: '100%', height: '90vh'}}>
          {props.children}  
        </Row>
        <Row style={{height: '2vh', minHeight: '30px'}}></Row>
        {/* <p className="site-footer"> Evergren AI © {currentYear} </p> */}
      </Col>
      <Col style={{width: '3vw', minWidth: '30px'}}></Col>
      <Modal title="Account Settings" visible={showSettings} onOk={handleOk} onCancel={handleCancel}>
        <Settings />
      </Modal>
    </Row>
  )
}
