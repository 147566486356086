import { Button, Result } from "antd"
import { useHistory } from "react-router"
import React from 'react'
import ErrorSVG from '../../images/icons/Error.svg'

const ErrorIcon = () => (
  <img src={ErrorSVG} width="20%" alt=""/>
)

export default function PageNotExist() {
  let history = useHistory();
  
  const redirectToHome = () => {
    history.replace("/")
  }

  return (
    <Result
      icon={<ErrorIcon />}
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary" onClick={redirectToHome}>Back Home</Button>}
    />
  )
}