import React, { useState } from 'react'
import { Alert, Form, Input, Button } from 'antd'
import { useAuth } from '../../context/FirebaseAuth/AuthContext'
import '../styles/Signup.css'

export default function Signup() {
  const { signup, sendEmailVerification, logout } = useAuth();
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  async function onFinish(values) {
    setError(null)
    setMessage(null)
    setLoading(true)
    if (values.password !== values.confirmPass) {
      setLoading(false)
      return setError("Passwords do not match.")
    } 
    try {
      await signup(values.email, values.password)
      await sendEmailVerification()
      await logout()
      setMessage("Your account has been created. Please verify your email before logging in.")
    } catch(error) {
      setError(error.message)
    }
    setLoading(false)
  }

	return (
    <div className="signup">
      {error && <Alert message={error} type="error" className='signup-result'/> }
      {message && <Alert message={message} type="success" className='signup-result'/>}
      <Form
        name="sign_up_form"
        className="sign-up-form"
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <Form.Item 
          className="registration-form"
          name='email'
          rules={[{ required: true, message: 'Please provide your email' }]}
        >
          <Input type="email" placeholder="Email" size="large"/>
        </Form.Item>
        <Form.Item 
          className="registration-form"
          name='password'
          rules={[{ required: true, message: 'Please input your password' }]}
        >
          <Input type="password" placeholder="Password" size="large"/>
        </Form.Item>
        <Form.Item 
          className="registration-form"
          name='confirmPass'
          rules={[{ required: true, message: 'Please confirm your password' }]}
        >
          <Input type="password" placeholder="Confirm Password" size="large"/>
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Create Account
          </Button>
        </Form.Item> 
      </Form>
    </div>
	)
}
