import { gql } from '@apollo/client';

// ----------------------- ML -------------------------- //
export const RETRIEVE_MODEL_PREDICTIONS = gql`
  query getModelPredictions($projectId: String) {
    modelReports(projectId: $projectId) {
      id
      smiles
      name
      endpoint
      avalonPrediction
      axiomPrediction
      logP
      molWeight
      HDonors
      HAcceptors
      axiomHighlight
      alertID
      probability
    }
  }
`;

export const FETCH_COMMENTS = gql`
  query getComments($alertID: [String]) {
    alertComments(alertID: $alertID) {
      comments
      alertName
    }
  }
`

export const FETCH_EXAMPLES = gql`
  query fetchExamples($numExamples: Int!, $queryCompound: String!){
    trainingExamples(numExamples: $numExamples, queryCompound: $queryCompound) {
      casNo
      labels
      similarity
      molSVG
    }
  }
`

export const LIST_PROJECTS = gql`
  query listProjects($email: String!) {
    userProjects(email: $email) {
      dateCreated
      description
      title
      projectId
    }
  }
`

export const FETCH_USER_PREDICTIONS = gql`
  query userPredictions($email: String!, $endpoint: String!) {
    userPredictions(email: $email, endpoint: $endpoint) {
      numPredictions
      pastActivity {
        day
        value
      }
      avalonCounts {
        id
        value
      }
      axiomCounts {
        id
        value
      }
      alertCounts {
        key
        value
        alertName
      }
    }
  }
`

export const VERIFY_ACCOUNT_STATUS = gql`
  query accountStatus($email: String!) {
    accountStatus(email: $email) {
      active
    }
  }
`

export const GET_ACCOUNT_INFO = gql`
  query accountInfo($email: String!) {
    accountInfo(email: $email) {
      active
      expireDate
      license
    }
  }
`

export const GET_QUICKRUN_PROJECT = gql`
  query quickRunProject($email: String!) {
    quickRunProject(email: $email) {
      dateCreated
      description
      title
      projectId
    }
  }
`
  
// NOT USED FOR NOW
// export const RETRIEVE_MOLECULE_INFO = gql`
//   query getMoleculeInfo($id: String!) {
//     moleculeInfo(id: $id) {
//       id
//       smiles
//       axiomHighlight
//       logP
//       molWeight
//       HDonors
//       HAcceptors
//     }
//   }
// `


// ----------------------- USERS -------------------------- //