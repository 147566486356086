import React from "react"
import { Route } from "react-router-dom"
import { useAuth } from "../context/FirebaseAuth/AuthContext"
import Error from "./Error/GenericError"


export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        return (
          currentUser 
            ? <Component {...props} /> 
            : <Error status="403" message="Sorry, you are not authorized to access this page. Please login." />
        )
      }}
    ></Route>
  )
}