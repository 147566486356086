import { Row, Button, Col, Space, Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/HomeLayout.css'

const { Title } = Typography

export default function HomeLayout(props) {

  return (
    <Row wrap={false}>
      <Col className="home-page" style={{minHeight: '100vh', width: '45vw', minWidth: '550px'}}>
        <div 
          className="company-intro" 
          style={{
            width: '40vw',
            minWidth: '250px',
            maxWidth: '700px' 
         }}
        >
          <Title level={2} style={{
            color: 'white',
          }}>
            Neuraltox AI
          </Title>
          <Title level={4} style={{
            color: 'white',
          }}>
            AI solutions for toxicology evaluation and chemical safety assessment 
          </Title>
        </div>
      </Col>
      <Col style={{width: '3vw', minWidth: '40px'}}></Col>
      <Col style={{width: '52vw'}}>
        <div className='home-nav-bar'>
          <Space size='large'>
            <Button shape='round' size='large' type='primary'>
              <Link to="/">
                Get Started
              </Link>
            </Button>
            <Button shape='round' size='large' type='primary'>
              <Link to="/features">
                Features
              </Link>
            </Button>
            <Button shape='round' size='large' type='primary'>
              <Link to="/services">
                Services
              </Link>
            </Button>
            <Button shape='round' size='large' type='primary'>
              <Link to="/faq">
                FAQ
              </Link>
            </Button>
          </Space>
        </div>
        <div className="home-content">
          {props.children}
        </div>
      </Col>
      <Col style={{width: '3vw', minWidth: '40px'}}></Col>
      {/* <Col style={{width: '2vw', minWidth: '30px'}}></Col> */}
    </Row>
  )
}