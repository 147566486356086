import { message, Row, Alert, Col, Button, Modal } from 'antd';
import React, { useState } from 'react';
import MoleculeEditor from './MoleculeEditor';
import InputTable from './InputTable';
import './styles/Input.css'
import 'ketcher-react/dist/index.css'
import { useProjectContext } from '../context/ProjectInfo/ProjectContext';

function InputTab(props) {
  const [showEditor, setShowEditor] = useState(false)
  const { setSpreadsheetItems } = useProjectContext();

  async function handleExport(props) {
    const editor = window.ketcher 
    const smiles = await editor.getSmilesAsync()
    const drawnMolecule = {name: "n/a", smiles: smiles}
    if (smiles === "") {
      message.error('No molecule drawn in canvas.')
    } else {
      message.success('SMILES added to spreadsheet!')
    }
    setSpreadsheetItems(prevState => {
      const emptyIndex = prevState.findIndex(obj => obj.smiles === "" || obj.smiles === null)
      let updatedArray = [...prevState]
      updatedArray[emptyIndex] = drawnMolecule
      return updatedArray
    })
  }

  const openEditor = () => {
    setShowEditor(true)
  }

  const closeEditor = () => {
    setShowEditor(false)
  }

  return (
    <Row type="flex" wrap={false} className='input-tab' justify="center" style={{alignItems: 'center', marginTop: '1vh'}}> 
      <Modal 
        title='Molecule Editor' 
        centered 
        visible={showEditor} 
        width={window.innerWidth * 0.55} 
        onOk={closeEditor} 
        onCancel={closeEditor}
        footer={[
          <Button onClick={closeEditor}>
            Cancel
          </Button>,
          <Button type='primary' onClick={handleExport}>
            Add to Spreadsheet
          </Button>
        ]}
      >
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <MoleculeEditor height={window.innerHeight * 0.7} width={window.innerWidth * 0.5}/>
        </div>
      </Modal>

      <Col span={18}>
        <div className='input-table' style={{width: '100%'}}>
          <div className="input-help">
            <Alert
              description="Input SMILES. Maximum limit per batch prediction is 50."
              type="info"
              action={
                <Button size='middle' type='primary' onClick={openEditor}>Draw Molecule</Button>
              }
            />
          </div>
          <InputTable 
            project={props.project} 
            // height={window.innerHeight * 0.6} 
            // width={'100%'}
            height={window.innerHeight * 0.55}
            showEditor={setShowEditor}
          />
        </div>
      </Col>
    </Row>
  );
}

export default InputTab;