import { useLazyQuery } from "@apollo/client";
import { Tooltip, Spin, Menu, Button, Dropdown, Tabs, Modal } from "antd"
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FETCH_COMMENTS } from "../graphql/query";
import TrainingExamples from "./TrainingExamples";
import HTMLParser from 'html-react-parser';
import React from 'react'

const { TabPane } = Tabs;

export default function CommentsPanel(props) {
  const [getComments, {loading, data}] = useLazyQuery(
    FETCH_COMMENTS,
  )
  const [activeTab, setActiveTab] = useState("comments")
  const [displayedComments, setDisplayedComments] = useState(null)
  const [CommentsModal, setCommentsModal] = useState(false)
    
  useEffect(() => {
    if (props.alertID && props.alertID.length > 0) {
      getComments({variables: {alertID: props.alertID}});
    } else {
      setDisplayedComments(null)
    } 
  }, [props.alertID, getComments, data])

  useEffect(() => {
    if (!props.alertID) {
      setDisplayedComments(null)
      return
    }
    if (data && data.alertComments.length > 0) {
      setDisplayedComments(data.alertComments[0].comments)
    }
  }, [data, props.alertID])
  
  const changeTab = activeKey => {
    setActiveTab(activeKey)
  }

  const handleMenuClick = (item) => {
    setDisplayedComments(data.alertComments[item.key].comments)
  }

  if (data) {
    var alertMenu = (
      <Menu onClick={handleMenuClick}>
        {data.alertComments.map((obj, index) => {
          return (
            <Menu.Item key={index}>
              {obj.alertName}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }
  
  return (
    <>
      <Tabs type="card" size="small" onChange={changeTab}>
        <TabPane tab="Description" key="comments">
          <Spin spinning={loading} delay={100}>
            <div className="comments-panel">
              <div className="comments-menu">
                {
                  displayedComments && props.alertID &&
                  <div>
                    <div style={{display: 'inline-block'}}>
                      <Tooltip title="View comments in a modal window">
                        <Button type='primary' onClick={() => setCommentsModal(true)}>Open</Button>
                      </Tooltip>
                    </div>
                    <div style={{display: 'inline-block', marginLeft: '1%'}}>
                      <Dropdown overlay={alertMenu}>
                        <Button>
                          Detected Alerts <DownOutlined />
                        </Button>
                      </Dropdown> 
                    </div>
                    <div style={{display: 'inline-block', marginLeft: '2%'}}>
                      {props.alertID.length} alert(s) detected.
                    </div>
                    <p></p>
                  </div>
                }
              </div>
              <div 
                className='comments' 
                style={
                  {
                    height: '32vh', 
                    overflow: 'auto',
                    marginLeft: '0.5%',
                    marginTop: '3%',
                    textAlight: 'center'
                  }
                }
              >
                <div style={{width: '95%'}}>
                  {props.alertID && displayedComments 
                    ? (HTMLParser(displayedComments))
                    : (<p> No alerts were flagged by the rule-based model. </p>)
                  }
                </div>
              </div>
            </div>
          </Spin>
        </TabPane>
        <TabPane tab="Similar Examples" key="examples">
          <TrainingExamples activeTab={activeTab} queryCompound={props.queryCompound} />
        </TabPane>
      </Tabs>
      <Modal 
        title="Alert Comments" 
        visible={CommentsModal} 
        onCancel={() => setCommentsModal(false)}
        onOk={() => setCommentsModal(false)}
        width={'40vw'}
        bodyStyle={{height: '70vh', overflow: 'auto'}}
      >
        {displayedComments && HTMLParser(displayedComments)}
      </Modal>
    </>
  );
}